import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { change, Field, reduxForm } from 'redux-form'

import { isChangeName, isOptionsToChange, namesProducts, optionsToChange } from '../../../function/changeNameProduct'
import { getLegsFromServer, getMoldingFromServer } from '../../../../redux/productsReducer'
import { getMaterialsTypeFromServer } from '../../../../redux/materialsReducer'
import { createField, SelectInput } from '../../common/FormsControl'
import { FormInput, FormTextArea } from '../../orders/FormOrderBlock/FormOrderControl'
import { changeOrder, getOrdersFromServerWithStatus, actionsOrder } from '../../../../redux/ordersReducer'
import { DatePickerInput } from '../../orders/FormOrderBlock/DatePickerInput'
import useOnClickOutside from '../../../hooks/useOnClickOutside'
import MaterialLegsMoldings from './MaterialLegsMoldings/MaterialLegsMoldings'

import { required } from '../../../../utils/validators/validators'
import getIdFromName from '../../../function/getIdFromName'

import s from './modalInfo.module.scss'
import {AppStateType} from '../../../../redux/rootReducer'

export interface IContent {
  id: number | null
  production_date_from: string | null
  production_date_to: string | null
  delivery_date_from: string | null
  delivery_date_to: string | null
  document_link: string | undefined
  code: string
  items: Array<any>
}

interface IModalInfo {
  content: IContent
  isEdit?: boolean
  setIsEdit?: (isEdit: boolean) => void
  closeModal: (isModal: boolean) => void
  isLink: boolean
  isAvailableForEditing?: boolean
  changeName?: boolean
}

const ModalInfo: React.FC<IModalInfo> = (props) => {
  const userRole = useSelector((state: AppStateType) => state.auth.role)
  const formData = useSelector((state: AppStateType) => state.form.editOrder)
  const toDelivery = useSelector((state: AppStateType) => state.form.editOrder?.values?.delivery_date_to)
  const toProduction = useSelector((state: AppStateType) => state.form.editOrder?.values?.production_date_to)
  const legs = useSelector((state: AppStateType) => state.products.legs)
  const moldings = useSelector((state: AppStateType) => state.products.molding)
  const [count, setCount] = useState(0)
  const refEdit = useRef(null)
  const dispatch = useDispatch()
  const {
    content = {
      id: null,
      production_date_to: null,
      production_date_from: null,
      delivery_date_from: null,
      delivery_date_to: null,
      items: [],
      document_link: undefined,
      code: '',
    }, isEdit, setIsEdit,
    closeModal,
    isLink = true,
    isAvailableForEditing = false
  } = props

  useEffect(() => {
    dispatch(getLegsFromServer())
    dispatch(getMoldingFromServer())
    // eslint-disable-next-line
  }, [])

  // инициализируем форму при включении редактирования
  useEffect(() => {
    if (isEdit && content) {
      dispatch(change('editOrder', `id`, content.id))
      dispatch(change('editOrder', `production_date_from`, content.production_date_from))
      dispatch(change('editOrder', `production_date_to`, content.production_date_to))
      dispatch(change('editOrder', `delivery_date_from`, content.delivery_date_from))
      dispatch(change('editOrder', `delivery_date_to`, content.delivery_date_to))

      content?.items?.forEach((item, index) => {
        dispatch(change('editOrder', `items[${index}].id`, item.id))
        dispatch(change('editOrder', `items[${index}].quantity`, item.quantity))
        dispatch(change('editOrder', `items[${index}].legsName`, item.leg))
        dispatch(change('editOrder', `items[${index}].legs`, getIdFromName(legs, item.leg)))
        dispatch(change('editOrder', `items[${index}].moldingName`, item.molding))
        dispatch(change('editOrder', `items[${index}].molding`, getIdFromName(moldings, item.molding)))
        dispatch(change('editOrder', `items[${index}].note`, item.note))
        dispatch(change('editOrder', `items[${index}].materials_type`, item.materials_type))
        dispatch(change('editOrder', `items[${index}].note_tk1`, item.note_tk1))
        dispatch(change('editOrder', `items[${index}].note_tk2`, item.note_tk2))
        dispatch(change('editOrder', `items[${index}].schema`, item.schema))

        const materials = item.materials?.reduce((acc: Array<any>, item: any) => {
          acc.push({
            id: item.id,
            name: item.name,
            provider: item.provider
          })
          return acc
        }, [])
        dispatch(change('editOrder', `items[${index}].materials`, materials))

        const options = item.options?.reduce((acc: Array<any>, item: any) => {
          if (item.name.trim()) {
            acc.push({
              id: item.id,
              quantity: item.quantity
            })
          }
          return acc
        }, [])

        dispatch(change('editOrder', `items[${index}].options`, options))
      })
    }

    if (!isEdit && isAvailableForEditing) {
      setCount(count => count + 1)
    }
    // eslint-disable-next-line
  }, [dispatch, isEdit, content])

  // отправка измененных данных
  useEffect(() => {
    if (count > 1 && formData?.values) {
      const data = {
        "id": formData?.values?.id,
        "production_date_from": formData?.values?.production_date_from,
        "production_date_to": formData?.values?.production_date_to
                              || formData?.values?.production_date_from,
        "delivery_date_from": formData?.values?.delivery_date_from,
        "delivery_date_to": formData?.values?.delivery_date_to
                              || formData?.values?.delivery_date_from,
        "items": [
          ...formData?.values?.items?.map((item: any) => {
            return {
              "id": item.id,
              "quantity": item.quantity,
              "leg": item.legs,
              "molding": item.molding,
              "materials_type": item.materials_type,
              "note_tk1": item.note_tk1,
              "note_tk2": item.note_tk2,
              "schema": item.schema,
              "note": item.note || '',
              "materials": [
                ...item.materials?.map((el: any) => {
                  return {
                    "id": el.id,
                    "name": el.name,
                    "provider": el.provider
                  }
                })
              ],
              "options": [
                ...item.options?.map((el: any) => {
                  return {
                    "id": el.id,
                    "quantity": el.quantity
                  }
                })

              ]
            }
          })
        ]
      }

      dispatch(changeOrder(formData.values.id, data, () => {
        dispatch(getOrdersFromServerWithStatus(1))
      }))
    }
    // eslint-disable-next-line
  }, [count])

  const handleClickOutside = () => {
    if (isAvailableForEditing && setIsEdit) {
      setIsEdit(false)
    }
  }
  useOnClickOutside(refEdit, handleClickOutside)

  if (!content) return null

  const changeOrderToServer = (e: any) => {
    e.preventDefault()
    if (setIsEdit) {
      setIsEdit(!isEdit)
    }
  }

  const onChange = (e: any, index: number) => {
    if (e.target.value) {
      dispatch(getMaterialsTypeFromServer(e.target.value, (types: any, val: any) => {
        dispatch(change('editOrder', `items[${index}].note_tk1`, types[0] || ' '))
        dispatch(change('editOrder', `items[${index}].note_tk2`, types[1] || ' '))
        dispatch(change('editOrder', `items[${index}].materials_type`, +val))
      }))
      return
    }
    dispatch(change('editOrder', `items[${index}].note_tk1`, ''))
    dispatch(change('editOrder', `items[${index}].note_tk2`, ''))
    dispatch(change('editOrder', `items[${index}].materials_type`, null))
  }

  const closeModalOrderInfo = (e: any) => {
    e.preventDefault()
    closeModal(false)
    dispatch(actionsOrder.removeDataOrderFromStore())
  }

  return (
    <>
      <form autoComplete="off" ref={refEdit}>
        {
          !isAvailableForEditing && <button onClick={closeModalOrderInfo} className={s.closeModal}>x</button>
        }
        <div className={s.headerOrderBlock}>
        <span className={s.headerOrderLink}>
          {
            isLink
              ? <a href={content?.document_link} target={'_blank'} rel="noreferrer">
                  <h2 className={s.title}>Договор {content?.code}</h2>
                </a>
              : <h2 className={s.title}>Договор {content?.code}</h2>
          }

        </span>
          {
            userRole !== "Storage" && isAvailableForEditing &&
            <button className={s.changeOrder} onClick={changeOrderToServer}>
              {
                !isEdit ? 'Изменить' : 'отправить'
              }
            </button>
          }
        </div>

        <div className={s.time}>
          <h3 className={s.orderTitle}>Сроки</h3>
          <div className={s.date}>
            <span className={s.dateName}>Дата изготовления</span>
            {
              isEdit
                ? <div
                  // className={accessClass.join(' ')}
                >
                  <DatePickerInput
                    // pathName="order"
                    name="production_date"
                    formName="editOrder"
                    valueInput={content?.production_date_from ? {
                      from: content?.production_date_from,
                      to: content?.production_date_to
                    } : ''}
                    agreement={true}
                    formTo={toProduction}
                  />
                </div>
                : <span className={s.dateValue}>
                    {`${content?.production_date_from} ${
                      (content?.production_date_to && content?.production_date_to !== content?.production_date_from)
                        ? `- ${content?.production_date_to}` : ''}`}
                  </span>
            }
          </div>
          <div className={s.date}>
            <span className={s.dateName}>Дата доставки</span>
            {
              isEdit
                ? <div
                  // className={accessClass.join(' ')}
                >
                  <DatePickerInput
                    name="delivery_date"
                    formName="editOrder"
                    valueInput={content?.delivery_date_from ? {
                      from: content?.delivery_date_from,
                      to: content?.delivery_date_to
                    } : ''}
                    agreement={true}
                    formTo={toDelivery}
                  />
                </div>
                : <span className={s.dateValue}>
                  {`${content?.delivery_date_from} ${
                    (content?.delivery_date_to && content?.delivery_date_to !== content?.delivery_date_from) ? `- ${content?.delivery_date_to}` : ''}`}
                </span>
            }
          </div>
        </div>
        <div className={s.good}>
          <div className={s.goods}>
            {
              content?.items?.map((item, index) => (
                <div key={index} className={s.goodWrap}>
                  <h3 className={s.orderTitle}>Информация о товаре</h3>
                  <div className={s.goodItem}>
                    <div className={s.goodHeader}>
                      <span className={s.goodNum}>№</span>
                      <span className={s.goodName}>Наименование</span>
                      <span className={s.goodQuantity}>Кол-во</span>
                    </div>

                    <div className={s.goodContent}>
                      <span className={s.goodNum}>1</span>
                      <span className={s.goodName}>
                        { item.product }
                      </span>
                      {
                        isEdit
                          ? <Field
                            className={s.goodInputQuantity}
                            name={`items[${index}].quantity`}
                            component={FormInput}
                          />
                          : <span className={s.goodQuantity}>{item.quantity}</span>
                      }
                    </div>

                    {
                      item.options.map((el: any, idx: number) => (
                        <div key={idx} className={s.goodContent}>
                          <span className={s.goodNum}>{idx + 2}</span>
                          <span className={s.goodName}>{el.name}</span>
                          {
                            isEdit
                              ? <Field
                                className={s.goodInputQuantity}
                                name={`items[${index}].options[${idx}].quantity`}
                                component={FormInput}
                              />
                              : <span className={s.goodQuantity}>{el.quantity}</span>
                          }
                        </div>
                      ))
                    }
                  </div>

                  <div key={index} className={s.equipmentWrap}>
                    <h3 className={s.orderTitle}>Комплектация
                      {
                        isChangeName(namesProducts, item?.product) && isOptionsToChange(optionsToChange, item?.options)
                          ? item.product.replace('Д/О', ' Д.к. ')
                          : ` ${item.product}`
                      }
                    </h3>

                    <div className={s.equipment}>
                      <div className={s.equipmentHeader}>
                        <span className={s.equipmentMaterial}>Материал</span>
                        <span className={s.equipmentName}>Наименование</span>
                        <span className={s.equipmentProvider}>Поставщик</span>
                      </div>
                      {
                        item?.materials?.map((item: any, idx: number) => {
                          if (idx < 3) {
                            return <div key={idx} className={s.equipmentContent}>
                              <span className={s.equipmentMaterial}>{item.material}</span>
                              {
                                isEdit
                                  ? <div className={s.wrapInput}>
                                    <Field
                                      className={s.goodInput}
                                      name={`items[${index}].materials[${idx}].name`}
                                      component={FormInput}
                                    />
                                  </div>
                                  : <span className={s.equipmentName}>{item.name}</span>
                              }
                              {
                                isEdit
                                  ? <div className={s.wrapInput}>
                                    <Field
                                      className={s.goodInput}
                                      name={`items[${index}].materials[${idx}].provider`}
                                      component={FormInput}
                                    />
                                  </div>
                                  : <span className={s.equipmentProvider}>{item.provider}</span>
                              }
                            </div>
                          }
                          return false
                        })
                      }

                      <div className={s.equipment}>
                        <div className={s.equipmentHeader}>
                          <span className={s.equipmentMaterial}>Материал</span>
                          <span className={s.equipmentName}>Наименование</span>
                        </div>

                        <MaterialLegsMoldings isEdit={isEdit}
                                              item={item}
                                              index={index}
                                              legs={legs}
                                              moldings={moldings}
                        />

                      </div>

                      <div className={s.note}>

                        <div className={s.noteWrap}>
                          <h3 className={s.orderTitle}>Примечание</h3>

                          <div className={s.typeWrap}>
                            <span className={s.type}>Вариант исполнения {item.materials_type}</span>

                            {
                              isEdit &&
                              createField('', `items[${index}].materials_type`, [required], SelectInput, {
                                className: s.select,
                                classWrap: s.selectWrap,
                                onChange: (e: HTMLInputElement) => onChange(e, index),
                                selectedNum: item.materials_type
                              })
                            }
                          </div>

                        </div>

                        <div className={s.tks}>
                          <div className={s.tk}>
                            <span className={s.tkHeader}>TK1</span>
                            {
                              isEdit
                                ? <Field
                                  className={s.tkValue}
                                  name={`items[${index}].note_tk1`}
                                  component={FormInput}
                                  disabled={true}
                                />
                                : <span className={s.tkValue}>{item.note_tk1}</span>
                            }
                          </div>
                          <div className={s.tk}>
                            <span className={s.tkHeader}>TK2</span>
                            {
                              isEdit
                                ? <Field
                                  className={s.tkValue}
                                  name={`items[${index}].note_tk2`}
                                  component={FormInput}
                                  disabled={true}
                                />
                                : <span className={s.tkValue}>{item.note_tk2}</span>
                            }
                          </div>
                        </div>

                        {
                          isEdit
                            ? <Field
                              className={s.fieldNoteArea}
                              name={`items[${index}].note`}
                              component={FormTextArea}
                            />
                            : <div className={s.fieldNote}>
                              {item.note}
                            </div>
                        }
                      </div>

                      <div className={s.scheme}>
                        <span className={s.schemeName}>Схема товара</span>
                        {
                          isEdit
                            ? <Field
                              className={s.schemeValue}
                              name={`items[${index}].schema`}
                              component={FormInput}
                            />
                            : <span className={s.schemeValue}>{item.schema}</span>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </div>
      </form>
    </>
  )
}

interface IModalInfoReduxForm {
  changeName: boolean
  content: IContent | null
  production_date: string
  delivery_date: string
  [propName: string]: any
}

export default reduxForm<IModalInfoReduxForm, IModalInfo>({form: 'editOrder'})(ModalInfo)